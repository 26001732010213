import React from "react";
import { Route, Switch } from "react-router-dom";

import Traec from "traec";

import { Footer } from "AppSrc/footer";
import { connect } from "react-redux";

import { setNavBarItems } from "traec-react/navBar";
import { setSideBarItems } from "traec-react/sideBar";
import { ErrorBoundary } from "traec-react/errors";

import {
  CompanyPermission,
  getCompanyPermissions,
  companyPermissionFilter,
  companyPermissionCheck
} from "traec/utils/permissions/company";
import CompanyTree from "AppSrc/tree";
import { CompanyRouter } from "./router";

import BootstrapSplitPane from "traec-react/utils/bootstrap/splitbs";

class CompanyPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      setNavBar: false,
      setSideBar: false,
      showSideBar: true
    };

    // Data required from the API for this Component
    this.requiredFetches = [
      new Traec.Fetch(
        "company",
        "read",
        {},
        {
          preUpdateHook: args => ({ ...args, companyId: this.props.match.params._companyId })
        }
      )
    ];

    // Action bindings
    this.navbarLinks = this.navbarLinks.bind(this);
    this.sideBarLinks = this.sideBarLinks.bind(this);
  }

  componentDidMount() {
    let { companyId } = this.props.match.params;
    companyPermissionCheck(companyId, false, []);
    Traec.fetchRequiredFor(this);
    this.setNavBar();
  }

  componentDidUpdate(prevProps) {
    let { companyId, setStyles } = this.props;

    this.setStyles();

    companyPermissionCheck(companyId, false, []);
    Traec.fetchRequiredFor(this);

    if (companyId && companyId != prevProps.companyId) {
      this.setState({
        setSideBar: false,
        setNavBar: false
      });
    }

    this.setSideBar();
    this.setNavBar();
  }

  setStyles() {
    let { setStyles, dispatch } = this.props;
    if (!setStyles) {
      return null;
    }
    console.log("UPDATING GLOBAL STYLES", setStyles.toJS());
    dispatch({
      type: "UI_SET_IN",
      payload: setStyles,
      stateParams: { itemPath: `styles` }
    });
  }

  setNavBar(forceUpdate = false) {
    let navBarLinks = this.navbarLinks();
    if ((!this.state.setNavBar && navBarLinks) || forceUpdate) {
      this.setState({ setNavBar: true });
      this.props.dispatch(setNavBarItems(navBarLinks));
    }
  }

  setSideBar(forceUpdate = false) {
    let sideBarLinks = this.sideBarLinks();
    if ((!this.state.setSideBar && sideBarLinks) || forceUpdate) {
      this.setState({ setSideBar: true });
      this.props.dispatch(setSideBarItems(sideBarLinks));
    }
  }

  navbarLinks() {
    let { companyId } = this.props;

    let _companyId = companyId ? companyId.substring(0, 8) : companyId;
    let companyBase = `/company/${_companyId}`;

    let items = [
      {
        label: "Company",
        requiresAdmin: false,
        to: [
          { label: "Company Dashboard", to: companyBase, octicon: "home" },
          {
            label: "Members",
            to: `${companyBase}/members`,
            octicon: "organization",
            requiresAdmin: false,
            requiredActions: ["READ_COMPANY_MEMBER"]
          }
        ]
      },
      {
        label: "Admin",
        requiresAdmin: true,
        to: [
          { label: "Indicators", to: `${companyBase}/indicators`, octicon: "issue-closed", requiresAdmin: true },
          { label: "Settings", to: `${companyBase}/details`, octicon: "gear", requiresAdmin: true },
          { label: null },
          { label: "Email Settings", to: `${companyBase}/email/settings`, octicon: "inbox", requiresAdmin: true },
          { label: "Email Statistics", to: `${companyBase}/email/report`, octicon: "mail", requiresAdmin: true }
        ]
      }
    ];
    return companyPermissionFilter(companyId, items);
  }

  render_sidebar() {
    let { companyId, companyList, company } = this.props;
    let companyBase = `/company/${companyId}`;

    if (!this.state.showSideBar) {
      return null;
    }

    return (
      <div className="mt-3">
        <CompanyTree company={company} companyList={companyList} currentId={companyId} />
      </div>
    );
  }

  sideBarLinks() {
    let { companyId } = this.props;
    companyId = companyId ? companyId.substring(0, 8) : companyId;
    let companyBase = `/company/${companyId}`;
    let items = [
      { label: "Company Dashboard", to: companyBase, octicon: "home" },
      {
        label: "Members",
        to: `${companyBase}/members`,
        octicon: "organization",
        requiresAdmin: false,
        requiredActions: ["READ_COMPANY_MEMBER"]
      },
      { label: "Indicators", to: `${companyBase}/indicators`, octicon: "issue-closed", requiresAdmin: true },
      { label: "Settings", to: `${companyBase}/details`, octicon: "gear", requiresAdmin: true }
    ];
    return companyPermissionFilter(companyId, items);
  }

  render() {
    let { companyId, company } = this.props;
    if (!company) {
      return null;
    }

    return (
      <CompanyPermission companyId={companyId} requiresAdmin={false} requiredActions={["READ_COMPANY_REPORT"]}>
        <div className="container-fluid">
          <div className="row">
            <BootstrapSplitPane
              localStorageKey={`explorer-grid-split-${companyId}`}
              pane1ClassName={"page-sidebar vh100-navbar"}
              onCollapseHook={() => {
                this.setState({ showSideBar: false });
              }}
              onExpandHook={() => {
                this.setState({ showSideBar: true });
              }}
              onDragFinished={draggedSize => {
                this.setState({ draggedSize });
              }}
              pane1Style={{
                borderRight: "1px solid grey"
              }}
            >
              <div>
                <ErrorBoundary>
                  <Switch>
                    <Route path="/project/:_projectId" component={CompanyTree} />
                    <Route path="/company/:_companyId" component={CompanyTree} />
                  </Switch>
                </ErrorBoundary>
              </div>
              <div>
                <ErrorBoundary>
                  <CompanyRouter
                    companyId={companyId}
                    company={company}
                    hasSideBar={this.state.showSideBar}
                    draggedSize={this.state.draggedSize}
                  />
                </ErrorBoundary>
              </div>
            </BootstrapSplitPane>
          </div>
        </div>
        <Footer />
      </CompanyPermission>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { companyId } = Traec.utils.getFullIds(state, ownProps.match.params);

  let company = state.getInPath(`entities.companies.byId.${companyId}`);
  let companyList = state.getInPath("entities.companies.byId");
  let userPermissions = getCompanyPermissions(state, companyId);

  // Get the company styles (if provided)
  let styles = company ? company.getInPath("meta_json.styles") || Traec.Im.Map() : Traec.Im.Map();
  let currentStyles = state.getInPath("ui.styles") || Traec.Im.Map();
  let setStyles = styles.equals(currentStyles) ? null : styles;

  return { companyId, company, companyList, styles, setStyles, userPermissions };
};

export default connect(mapStateToProps)(CompanyPage);
